import React from "react";

import NavbarDark from "./NavbarDark";

import { useNavbar } from "./useNavbar";

export default function NavbarIndex() {
  const { state, toggleRegistrationModal, handleSuccessGoogleLogin } = useNavbar();
  return (
    <NavbarDark
      state={state}
      toggleRegistrationModal={toggleRegistrationModal}
      onSuccessGoogleLogin={handleSuccessGoogleLogin}
    />
  );
}
