import style from "./PreFooter.module.scss";

import CollegesImg from "assets/images/colleges.webp";
import CompaniesImg from "assets/images/companies.webp";
import PrefooterBannerImg from "assets/images/prefooterBanner.webp";

export default function PreFooter() {
  return (
    <div className={style.wrapper}>
      <section className={style.insts}>
        <h1 data-testid="footer-head1">
          We partner & train students primarily from premier Institutes in India
        </h1>
        <p data-testid="footer-head2">
          Students from 135 Institutions / Universities have benefitted from our trainings
        </p>
        <img src={CollegesImg} alt="colleges" data-testid="footer-image" />
        <div className={style.footer} data-testid="footer-head3">
          125+ more Institutions
        </div>
      </section>

      <section className={style.companies}>
        <h1 data-testid="footer1-head1">Our Alumni work at the best companies in the world</h1>
        <p data-testid="footer1-head2">
          Our 611000+ alumni have got placed in 758 companies with 1.22Crs as highest package
        </p>
        <img src={CompaniesImg} alt="colleges" data-testid="footer1-image" />
      </section>

      <div className={style.footer} data-testid="footer1-head3">
        730+ more Companies
      </div>

      <div className={style.banner}>
        <img src={PrefooterBannerImg} alt="banner" data-testid="banner-image" />
      </div>
    </div>
  );
}
