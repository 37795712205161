import React from "react";
import style from "./Login.module.scss";
import { useEffect } from "react";

import { useLogin } from "./useLogin";

import { FiEye, FiEyeOff } from "react-icons/fi";
import clsx from "clsx";
import { Roles } from "utils/consts";
import { routes } from "index/Routes";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { state, handleChangeForm, handleSubmitForm, togglePassVisibility } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("role") !== Roles.InstAdmin &&
      localStorage.getItem("role") !== Roles.SuperAdmin
    )
      localStorage.clear();
    else navigate(`${routes.StudCodingProfiles}`);
  }, []);
  return (
    <div className={style.wrapper}>
      <form onChange={handleChangeForm} onSubmit={handleSubmitForm}>
        <div className={style.caption}>Please enter admin credentials</div>
        <label>Username</label>
        <input required type="email" name="email" />
        <label>Password</label>
        <div className={style.passwordWrapper}>
          <input
            data-testid="passwordInput"
            required
            type={!state.isPassVisible ? "password" : "text"}
            name="password"
          />
          <button type="button" data-testid="eye" onClick={togglePassVisibility}>
            {!state.isPassVisible ? <FiEye /> : <FiEyeOff />}
          </button>
        </div>

        <button type="submit" data-testid="login-submit" className={style.submitBtn}>
          Login
        </button>
        <p
          data-testid="form-error"
          className={clsx(style.error, !state.isValid && style.showError)}
        >
          Invalid credentials, try again
        </p>
      </form>
    </div>
  );
}
