import { useState } from "react";
import styles from "./ProfileContainer.module.scss";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { FiUser as UserIcon } from "react-icons/fi";
import { BsChevronDown as ChevronDown, BsChevronRight as ChevronRight } from "react-icons/bs";
import { ClickAwayListener } from "@mui/base";

import { useUserContext } from "contexts/UserContext";

import { NavLinks } from "utils/consts";
import { handleLogout } from "utils/helper";

import { FiClock } from "react-icons/fi";

interface PropTypes {
  color: string;
  accessDate: any;
}

export default function ProfileContainer(props: PropTypes) {
  const [isOpen, setIsOpen] = useState(false);

  const userContext = useUserContext();
  const navigate = useNavigate();

  const userProfile = userContext.state.profile;
  const hasUserAccessExpired = userContext.state.hasAccessExpired;

  function handleClickNav(navItem: { name: any; link: any }): void {
    toggleDropdown();

    const { name, link } = navItem;
    if (name.toLowerCase() === "logout") handleLogout();

    navigate(link);
  }

  function toggleDropdown(): void {
    setIsOpen((prev) => !prev);
  }

  function handleClickAway() {
    setIsOpen(false);
  }
  const currNavLinks = NavLinks[localStorage.getItem("role")];

  return (
    <div className={clsx(styles.wrapper, styles[props.color])}>
      <div data-testid="menu-toggle" className={styles.top} onClick={toggleDropdown}>
        <span className={styles.image} style={{ border: "1px solid white" }}>
          <UserIcon />
        </span>

        <span className={styles.name}>
          <span>{userProfile?.firstName}</span>
          <span>{isOpen ? <ChevronDown size={13} /> : <ChevronRight size={13} />}</span>
        </span>
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div data-testid="drop-down" className={styles.dropdownWrapper}>
            {currNavLinks.map((navLink) => (
              <button
                key={navLink.name}
                disabled={navLink.name.toLowerCase() !== "logout" && hasUserAccessExpired}
                className={clsx(styles.menu)}
                onClick={handleClickNav.bind(this, navLink)}
              >
                {navLink.icon}
                <p>{navLink.name}</p>
              </button>
            ))}

            {(localStorage.getItem("role") === "institutionAdmin" ||
              localStorage.getItem("role") === "institutionAdminLimited") && (
              <div className={clsx(styles.menu, styles.accessDate)}>
                <FiClock />

                {!hasUserAccessExpired ? (
                  <p>
                    Subscription ends on <span>{props.accessDate}</span>
                  </p>
                ) : (
                  <p style={{ color: "rgba(1,1,1,0.5)" }}>
                    Your subscription ended <span>{props.accessDate}</span>
                  </p>
                )}
              </div>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
