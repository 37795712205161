import CodeForcesImg from "assets/images/coding-platforms/codeforces.png";
import TopCoderImg from "assets/images/coding-platforms/topcoder.png";
import CodeChefImg from "assets/images/coding-platforms/codechef.png";
import HackerRankImg from "assets/images/coding-platforms/hackerrank.png";
import HackerEarthImg from "assets/images/coding-platforms/hackerearth.png";
import LeetCodeImg from "assets/images/coding-platforms/leetcode.png";
import KickStartImg from "assets/images/coding-platforms/kickstart.png";
import AtCoderImg from "assets/images/coding-platforms/atcoder.png";
import OthersImg from "assets/images/coding-platforms/others.png";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js";

import { Navigate } from "react-router-dom";

export function makeCalendarLink(startTime, endTime, text, link) {
  return `https://calendar.google.com/calendar/u/0/r/eventedit?ctz=Asia%2FKolkata&dates=${startTime}/${endTime}&text=${text}&location=${link}/&pli=1`;
}

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};
export const convertHtmlToEditorState = (html) => {
  const contentBlock = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  return EditorState.createWithContent(contentState);
};

export const findIndexInObjArray = (myArray, searchTerm, property) => {
  for (var i = 0, len = myArray.length; i < len; i++) {
    if (myArray[i][property] === searchTerm) return i;
  }
  return -1;
};
export function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

export const getRandomNumber = (start, end) => {
  return Math.floor(Math.random() * end) + start;
};

export function capitalizeFirstLetter(word) {
  if (typeof word !== "string") return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function sortArrOfObjects(arr, param, type) {
  if (type === "desc")
    return arr.sort((a, b) => (a[param] > b[param] ? -1 : b[param] > a[param] ? 1 : 0));
  else if (type === "asc")
    return arr.sort((a, b) => (a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0));
}

export function updateObjectWithMatch(toUpdate, newData) {
  let toUpdateNew = { ...toUpdate };

  for (const key in newData) {
    toUpdateNew[key] = newData[key];
  }

  return { ...toUpdateNew };
}

export function updateMultipleWithMatch(state, payload) {
  let toUpdateNew = { ...state };

  for (const key in payload) {
    toUpdateNew[key] = updateObjectWithMatch(state[key], payload[key]);
  }

  return toUpdateNew;
}

export function arrayRandomShuffle(arr) {
  // return arr
  //   .map((value) => ({ value, sort: Math.random() }))
  //   .sort((a, b) => a.sort - b.sort)
  //   .map(({ value }) => value);

  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

export function splitArrChunks(arr, noOfChunks) {
  if (!arr || !noOfChunks) throw new Error("Params missing");

  return arr.reduce((accum, curr, index, array) => {
    if (index % noOfChunks === 0) accum.push(array.slice(index, index + noOfChunks));
    return accum;
  }, []);
}

export function removeSpaces(string) {
  return string.toLowerCase().replace(/\s/g, "");
}

export const PlatformLogoMap = {
  TopCoder: TopCoderImg,
  CodeChef: CodeChefImg,
  CodeForces: CodeForcesImg,
  HackerRank: HackerRankImg,
  HackerEarth: HackerEarthImg,
  LeetCode: LeetCodeImg,
  Google: KickStartImg,
  AtCoder: AtCoderImg,
  "Kick Start": KickStartImg,
  Others: OthersImg,
};

export const PlatformProfileUrlMap = {
  CodeChef: `codechef.com/users/`,
  LeetCode: `leetcode.com/`,
  HackerRank: `hackerrank.com/profile`,
  HackerEarth: `hackerearth.com/@`,
  CodeForces: `codeforces.com/profile/`,
};

export function setAuthToken(token) {
  return localStorage.setItem("authToken", token);
}

export function getAuthToken() {
  return localStorage.getItem("authToken");
}

export function getRole() {
  return localStorage.getItem("role");
}

export function isLoggedIn() {
  if (getAuthToken()) return true;
  return false;
}

export function withAuth(element, redirectTo = "/contests") {
  if (isLoggedIn()) return element;
  else return <Navigate to={redirectTo} />;
}

export function stateUpdater(setState) {
  return (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
}

export function convertArrToObj(arr, key) {
  const obj = {};
  arr.forEach((item) => {
    obj[item[key]] = item;
  });
  return obj;
}

export function handleLogout() {
  if (getRole() === "student") {
    localStorage.clear();
    window.location.href = "/contests";
  } else {
    localStorage.clear();
    window.location.href = "/admin";
  }
}
export function processImages(questions) {
  const processedQuestions = [];

  questions.forEach((question) => {
    const { body, choices, images: imageUrls } = question;
    let regex = /\.com(.*?)\?/g;
    let match = body.matchAll(regex);
    const matches = [...match];

    if (matches) {
      matches.forEach((match) => {
        let extracted = match[1].substring(1);
        const fileName = extracted.split(".")[0];

        let regexPattern = new RegExp(`<img src="[^"]*${fileName}[^"]*\\.jpeg\\?[^"]*"`, "g");

        question.body = question.body.replace(
          regexPattern,
          '<img src="' + imageUrls[extracted] + '"',
        );
      });
    } else {
      console.log("No match found.");
    }

    const processedChoices = choices.map((choice) => {
      const { body } = choice;

      let choiceMatch = body.matchAll(regex);
      const choiceMatches = [...choiceMatch];
      if (choiceMatches) {
        choiceMatches.forEach((match) => {
          let extracted = match[1].substring(1);
          const fileName = extracted.split(".")[0];

          let regexPattern = new RegExp(`<img src="[^"]*${fileName}[^"]*\\.jpeg\\?[^"]*"`, "g");
          choice.body = choice.body.replace(
            regexPattern,
            '<img src="' + imageUrls[extracted] + '"',
          );
        });
      } else {
        console.log("No match found.");
      }

      return choice;
    });

    processedQuestions.push({
      ...question,
      choices: processedChoices,
    });
  });

  return processedQuestions;
}

export function delay(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(`Reject after ${ms} milliseconds`);
    }, ms);
  });
}
