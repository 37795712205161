import { fetchUserProfile } from "apis/user";
import { getCurrentDateTime } from "apis/setup";
import { useReducer, useEffect, createContext, useContext } from "react";

import { isLoggedIn } from "utils/helper";
import { useAppContext } from "./AppContext";
import { useNavigate } from "react-router-dom";
import { routes } from "index/Routes";

import useEffectLog from "hooks/useEffectLog";

export const UserContext = createContext();

const initialState = {
  profile: {},
  hasAccessExpired: false,
  isLoading: false,
};

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case "SET_PROFILE":
      return { ...state, profile: payload };
    case "SET_HAS_ACCESS_EXPIRED":
      return { ...state, hasAccessExpired: payload };
    case "SET_LOADING":
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}

export function UserContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setIsOpenProfileUpdateModal } = useAppContext();
  const navigate = useNavigate();
  function getUserProfile() {
    dispatch({ type: "SET_LOADING", payload: true });
    getCurrentDateTime()
      .then(({ dateTimeFromApi, isTimeDifferenceAcceptable }) => {
        if (!isTimeDifferenceAcceptable) {
          navigate(`${routes.Error}/time-error`);
        }
      })
      .catch((error) => {
        console.error("Error fetching current date and time:", error);
      });
    fetchUserProfile()
      .then((resp) => {
        dispatch({ type: "SET_PROFILE", payload: resp.data.data });

        if (resp.data.data.accessExpiryTime) {
          const hasExpired =
            new Date().getTime() > new Date(resp.data.data.accessExpiryTime).getTime();
          dispatch({ type: "SET_HAS_ACCESS_EXPIRED", payload: hasExpired });
        }

        if (resp.data.data.isNew) setIsOpenProfileUpdateModal(true);
        localStorage.setItem("role", resp.data.data.role);
        localStorage.setItem("userId", resp.data.data._id);
        if (resp.data.data.role === "institutionAdminLimited") navigate(`/contests`);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch({ type: "SET_LOADING", payload: false });
      });
  }

  function refreshUserProfile() {
    getUserProfile();
  }

  useEffect(() => {
    if (isLoggedIn()) getUserProfile();
  }, []);

  useEffectLog(state);

  const value = {
    state,
    refreshUserProfile,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  return useContext(UserContext);
}
