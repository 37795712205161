import { getRequest, patchRequest, postRequest, deleteRequest } from "./setup";

export function fetchUserProfile() {
  return getRequest(`/users/profile`);
}

export function fetchCodechefProfile() {
  return getRequest(`/coding/profile`);
}

export function patchStudentProfile(body) {
  return patchRequest(`/users/profile`, body);
}

export function fetchStudentPlatformProfile(platformName, userId) {
  let url = `/users/profile/platform/${platformName}`;
  if (userId) url += `?userId=${userId}`;
  return getRequest(url);
}

export function fetchCollegePlatformStudents(platformName, query) {
  let url = `/institutions/students/platform/${platformName}`;

  if (query) return getRequest(url, query);
  else return getRequest(url);
}

export function fetchAllStudents(query, type) {
  let url = `/institutions/students/all`;
  return getRequest(url, query, type);
}

export function approveStudent(userId) {
  return patchRequest(`/users/students/approve`, { userId: userId });
}

export function scrapeCodechefProfile(userId) {
  return postRequest(`/users/profile/platform/codechef`, { userId: userId });
}

export function approveAll(query) {
  if (query) return patchRequest(`/institutions/students/approve/all`, undefined, query);
  else return patchRequest(`/institutions/students/approve/all`);
}

export function getValidationStatus(platform, userId) {
  return getRequest(`/validity/${platform}`, { userId: userId });
}
export function addStudentToGroup(body) {
  return postRequest(`/users/groups/users`, body);
}
export function addAllStudentToGroup(body) {
  return postRequest(`/users/groups/users/all`, body);
}
export function removeStudentFromGroup(body) {
  return deleteRequest(`/users/groups/users`, body);
}
export function fetchProblemProgressStatus(query) {
  return getRequest(`/users/problems/status`, query);
}
