/** @format */

import React from "react";
import style from "./StudentRegistration.module.scss";

import { BsLinkedin, BsGithub, BsArrowRightCircle } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

import Input from "components-shared/form/Input";
import InputRadio from "components-shared/form/InputRadio";
import InputSelect from "components-shared/form/InputSelect";
import Modal from "components-shared/Modal/Modal";
import Button from "components-shared/Button/Button";

import { useStudentRegistration } from "./useStudentRegistration";

import { useUserContext } from "contexts/UserContext";
import { PlatformLogoMap, PlatformProfileUrlMap } from "utils/helper";
import useGetInstitutionOptions from "hooks/useGetInstutionOptions";
import clsx from "clsx";
import useGetSpecialisationOptions from "hooks/useGetSpecialisationOptions";
import useGetBranchOptions from "hooks/useGetBranchOptions";
import useModalHandler from "hooks/useModalHandler";
import { Link } from "react-router-dom";
import { handleLogout } from "utils/helper";

import { PassOut } from "utils/consts";

const OPT_GENDER = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

const OPT_FULLSTACK_EXP = [
  { label: "No", value: false },
  { label: "yes", value: true },
];

const OPT_STREAM = [
  { label: "No", value: false },
  { label: "yes", value: true },
];

const Platforms = ["CodeChef", "LeetCode", "HackerRank", "CodeForces"];
const Socials = [
  {
    name: "linkedin",
    url: "www.linkedin.com/in/",
    icon: <BsLinkedin size={23} color="#0072b1" />,
  },
  { name: "github", url: "www.github.com/in/", icon: <BsGithub size={25} /> },
];

const Opt_Sem = [1, 2, 3, 4, 5, 6, 7, 8].map((item) => ({ label: item, value: item }));

export default function StudentRegistration({ onClose }) {
  const { state, handleChangeForm, handleSubmitForm, handleChangeDropdown, handleChangeRadio } =
    useStudentRegistration();

  const { state: userState } = useUserContext();

  const { options: optsInst } = useGetInstitutionOptions();
  const { options: optsSpecs } = useGetSpecialisationOptions();
  const { options: optsBranches } = useGetBranchOptions();
  const { closeModal, openModal, modalStates } = useModalHandler();

  return (
    <div className={style.wrapper}>
      <div className={style.caption}>
        <h4>Sign Up</h4>
        <span>
          <AiOutlineCloseCircle size={24} onClick={() => openModal("confirmModal")} />
        </span>
      </div>
      <form onChange={handleChangeForm} onSubmit={handleSubmitForm}>
        <section>
          <div className={style.row}>
            <InputRadio
              label="Are you Technical ?"
              options={OPT_STREAM}
              name="technical"
              validationErrors={state.errors}
              classForValidation="input-radio-fullstack"
              onChange={handleChangeRadio}
              defaultValue={false}
              labelClass="technicalLabel"
            />
          </div>
          <div className={style.row}>
            <Input
              label="First Name*"
              type="text"
              placeholder="First Name"
              name="firstName"
              validationErrors={state.errors}
              data-testid="user-firstname"
              classForValidation="user-firstname-err"
            />

            <Input
              label="Last Name*"
              type="text"
              placeholder="Last Name"
              name="lastName"
              validationErrors={state.errors}
              data-testid="user-lastname"
              classForValidation="user-lastname-err"
            />
          </div>

          <div className={style.row}>
            <InputRadio
              validationErrors={state.errors}
              label={"Gender*"}
              name="gender"
              options={OPT_GENDER}
              classForValidation="input-radio-gender"
              // data-testid="user-firstname"
            />
          </div>
        </section>

        <section>
          <div className={style.row}>
            <Input
              value={userState.profile.email}
              type="email"
              name="email"
              placeholder="Email Address"
              label="Email"
              readOnly={true}
              validationErrors={state.errors}
            />
          </div>

          <div className={style.row}>
            <InputSelect
              options={optsInst}
              onChange={handleChangeDropdown.bind(this, "INSTITUTION")}
              label="University / College Name*"
              placeholder="Select College"
              name="institutionId"
              validationErrors={state.errors}
              isSearchable={true}
              classNamePrefix="user-institution"
              classForValidation="user-inst-err"
            />
          </div>

          <div className={style.row}>
            <Input
              placeholder="Roll Number"
              name="instRollNumber"
              label="Institution Roll Number"
              validationErrors={state.errors}
              data-testid="user-roleNum"
              classForValidation="user-rollNum-err"
            />

            <InputSelect
              label="Select Semester"
              options={Opt_Sem}
              onChange={handleChangeDropdown.bind(this, "SEMESTER")}
              validationErrors={state.errors}
              name="semester"
              classNamePrefix="user-sem"
              classForValidation="user-sem-err"
              placeholder="Select a Semester"
            />
          </div>

          <div className={style.row}>
            <Input
              // type="email"
              placeholder="Institute Email"
              name="instEmailId"
              label="Institute Email Address"
              validationErrors={state.errors}
              data-testid="user-instEmail"
              classForValidation="user-instEmail-err"
            />
          </div>
          <div className={style.row}>
            <InputSelect
              options={PassOut}
              onChange={handleChangeDropdown.bind(this, "PASSOUT")}
              label="Pass Out Year"
              placeholder="Select Year"
              name="passoutYear"
              validationErrors={state.errors}
              // isSearchable={true}
              // classNamePrefix="user-institution"
              classForValidation="user-inst-err"
            />
          </div>

          <div className={style.row}>
            <InputSelect
              options={optsBranches}
              onChange={handleChangeDropdown.bind(this, "BRANCH")}
              label="Branch*"
              placeholder="Select Branch"
              name="branchId"
              validationErrors={state.errors}
              classNamePrefix="user-branch"
              classForValidation="user-branch-err"
            />
          </div>

          <div className={style.row}>
            <InputSelect
              options={optsSpecs}
              onChange={handleChangeDropdown.bind(this, "SPECIALISATION")}
              label="Specialisation*"
              placeholder="Select Specialisation"
              name="specialisationId"
              validationErrors={state.errors}
              classNamePrefix="user-spec"
              classForValidation="user-spec-err"
              isSearchable={true}
            />
          </div>

          {/* 
          <div className={style.row}>
            <Input label="Institution Roll Number*" type="text" placeholder="Roll Number" name="instRollNumber" />
            <InputSelect options={OPT_UNIV} label="Select Semester*" placeholder="Select" name="semester" />
          </div> */}

          {/* <div className={style.row}>
            <InputSelect name="section" placeholder="Select" options={OPT_UNIV} label="Class Section" />
          </div> */}
          {state.technical === "true" && (
            <div className={style.row}>
              <InputRadio
                label="Do you have experience in full stack development?"
                options={OPT_FULLSTACK_EXP}
                name="fullStackExp"
                validationErrors={state.errors}
                classForValidation="input-radio-fullstack"
              />
            </div>
          )}
        </section>
        {state.technical === "true" && (
          <section>
            <div className={style.header}>
              <div>Update your competitive coding platform profiles</div>
              {/* <div>Add at least 1 profile to sign up</div> */}
            </div>
            <div className={clsx(style.platforms, "nonDisabled")}>
              {Platforms.map((pfm) => (
                <div className={style.platform} key={pfm}>
                  <div className={style.image}>
                    <img alt={`${pfm}_logo`} src={PlatformLogoMap[pfm]} />
                  </div>

                  <div className={style.url}>{PlatformProfileUrlMap[pfm]}</div>

                  <Input
                    name={`platform_${pfm.toLowerCase()}`}
                    validationErrors={state.errors}
                    data-testid={`user-platform_${pfm.toLowerCase()}`}
                    classForValidation={`user-platform-error_${pfm.toLowerCase()}`}
                    disabled={false}
                    className="nonDisabled"
                  />
                </div>
              ))}
            </div>
          </section>
        )}
        {state.technical === "true" && (
          <section className={clsx(style.platforms, style.social)}>
            {Socials.map((social) => (
              <div className={style.platform} key={social.name}>
                <div className={style.image}>{social.icon}</div>
                <div className={style.url}>{social.url}</div>
                <Input
                  name={`${social.name.toLowerCase()}Id`}
                  validationErrors={state.errors}
                  data-testid={`${social.name.toLowerCase()}Id`}
                  classForValidation={`${social.name.toLowerCase()}Id-err`}
                />
              </div>
            ))}
          </section>
        )}

        <footer>
          {Object.keys(state.errors).length > 0 && (
            <p className={style.mainError}>*Please fill all the fields appropriately</p>
          )}
          <button data-testid="user-reg-btn">
            {state.isLoadingSubmit ? (
              <ClipLoader size={20} color="white" />
            ) : (
              <>
                Sign Up <BsArrowRightCircle size={18} />
              </>
            )}
          </button>
        </footer>
      </form>
      <Modal isOpen={modalStates.confirmModal} onClose={() => closeModal("confirmModal")}>
        <h4>Are you sure</h4>
        <p>You need to fill this form to proceed. </p>
        <div className={style.confirmModalbtns}>
          <Button
          // variant="warning"
          >
            <Link
              to="/contests"
              onClick={() => {
                closeModal("confirmModal");
                onClose();
                handleLogout();
              }}
            >
              Logout
            </Link>
          </Button>
          <Button onClick={() => closeModal("confirmModal")}>Cancel</Button>
        </div>
      </Modal>
    </div>
  );
}
