import React from "react";
import style from "./Modal.module.scss";

import clsx from "clsx";
interface PropTypes {
  className?: string;
  containerClassName?: string;
  title?: string;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  isOpen?: boolean;
  children?: React.ReactNode;
  showCloseIcon?: boolean;
}
export default function Modal({
  className,
  containerClassName,
  title,
  onClose,
  isOpen,
  children,
  showCloseIcon = true,
}: PropTypes) {
  return (
    isOpen && (
      <div className={clsx(style.backdrop, containerClassName)}>
        <div className={clsx(style.content, className)}>
          <header>
            <div className="">{title}</div>
            {showCloseIcon && (
              <span data-testid="close-modal" onClick={onClose}>
                &times;
              </span>
            )}
          </header>
          {children}
        </div>
      </div>
    )
  );
}
