import React from "react";
import style from "./GoogleLogin.module.scss";

import { GoogleLogin } from "@react-oauth/google";

export default function GoogleLoginBtn({ onSuccess, onError }) {
  return (
    <div className={style.wrapper}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          onSuccess(credentialResponse);
        }}
        onError={(err) => {
          onError(err);
        }}
      />
    </div>
  );
}
