import style from "./InputSelect.module.scss";
import Dropdown from "components-shared/Dropdown/Dropdown";

interface PropTypes {
  label: string;
  name: string;
  options?: any;
  placeholder?: string;
  onChange: (event: Event, prop: string) => void;
  validationErrors?: any;
  isSearchable?: boolean;
  classNamePrefix?: string;
  classForValidation?: string;
  defaultValue?: any;
}

export default function InputSelect({
  label,
  name,
  onChange,
  validationErrors,
  isSearchable,
  classForValidation,
  defaultValue,
  ...props
}: PropTypes) {
  function handleChange(e) {
    onChange(e, name);
  }

  return (
    <div className={style.wrapper}>
      <label>{label}</label>
      <Dropdown
        style={undefined}
        backgroundColor={"#e7f6ff"}
        onChange={handleChange}
        isSearchable={isSearchable}
        {...props}
      />
      {validationErrors && validationErrors[name] && (
        <p className={classForValidation}>{validationErrors[name].message}</p>
      )}
    </div>
  );
}
