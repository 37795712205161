import { useEffect, useState } from "react";

import { stateUpdater } from "utils/helper";
import { fetchAllInstitutions } from "apis/misc";

import { useUserContext } from "contexts/UserContext";
import { Roles } from "utils/consts";

export default function useGetInstitutionOptions() {
  const [state, setState] = useState({ options: [], isLoading: false });
  const { state: userState } = useUserContext();

  const updateState = stateUpdater(setState);

  function getInstitutions() {
    updateState("isLoading", true);
    fetchAllInstitutions()
      .then((resp) => {
        updateState(
          "options",
          resp.data.data.map((inst) => ({ label: inst.name, value: inst })),
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        updateState("isLoading", false);
      });
  }
  useEffect(() => {
    if (userState.profile?.role === Roles.InstAdmin) return;
    getInstitutions();
  }, [userState.profile?.role]);

  return { ...state };
}
