import "./index.scss";
import "./MaterialOverrides.scss";

import { BrowserRouter, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect } from "react";

import AppRouter from "./AppRouter";
import Footer from "components-layout/Footer";
import NavbarIndex from "components-layout/Navbar/Navbar";

import config from "config/config";
import { UserContextProvider } from "contexts/UserContext";
import { AppContextProvider } from "contexts/AppContext";

import { ToastContainer } from "react-toastify";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryHandler from "components/ErrorBoundary/ErrorBoundary";
import "react-toastify/dist/ReactToastify.min.css";
import { useAppContext } from "contexts/AppContext";
import { refreshAccessToken, getRefreshToken } from "apis/setup";
import { setAuthToken, getAuthToken, getRole } from "utils/helper";
import jwt_decode from "jwt-decode";
import { isLoggedIn } from "utils/helper";
import { ClipLoader } from "react-spinners";
import { useUserContext } from "contexts/UserContext";

import { Roles } from "utils/consts";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function AppContainer(): JSX.Element {
  const { state: userState } = useUserContext();
  return (
    <>
      {userState.isLoading ? (
        <div className="main-loader">
          <ClipLoader size={50} color="green" />
        </div>
      ) : (
        <AppRouter />
      )}
    </>
  );
}
function AppWrapper(): JSX.Element {
  const { state: appState, setLoading } = useAppContext();
  let location = useLocation();

  function checkToken() {
    if (!isLoggedIn()) {
      setLoading(false);
    } else {
      const token = getAuthToken();
      const decodedToken: { exp: number } = jwt_decode(token);
      const tokenExp: any = decodedToken.exp * 1000;
      const currentTime = new Date().getTime();
      const timeDifferenceInMilliseconds = tokenExp - currentTime;

      if (timeDifferenceInMilliseconds < 3600000) {
        if (!getRefreshToken()) {
          localStorage.clear();
          window.location.href = "/";
        }
        setLoading(true);
        refreshAccessToken()
          .then((resp) => {
            setAuthToken(resp.data.data.token);
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    checkToken();
  }, [location]);
  return (
    <>
      {!appState.isLoading ? (
        <UserContextProvider>
          <GoogleOAuthProvider clientId={config.googleClientId}>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />

            <NavbarIndex />

            <div className={"mainWrapper"}>
              <AppContainer />
            </div>

            {getRole() !== Roles.ContentAdmin && <Footer />}
          </GoogleOAuthProvider>
        </UserContextProvider>
      ) : (
        <div className="main-loader">
          <ClipLoader size={50} color="green" />
        </div>
      )}
    </>
  );
}

function App(): JSX.Element {
  return (
    <ErrorBoundary fallback={<ErrorBoundaryHandler />}>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <BrowserRouter>
            <AppWrapper />
          </BrowserRouter>
        </AppContextProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
