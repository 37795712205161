import { getRequest } from "./setup";

export function fetchAllPlatforms() {
  return getRequest(`/platforms/all`);
}

export function fetchAllInstitutions() {
  return getRequest(`/institutions/all`);
}

export function fetchAllSpecialisations() {
  return getRequest(`/institutions/specialisations/all`);
}

export function fetchAllBranches() {
  return getRequest(`/institutions/branches/all`);
}

export function fetchAllTopics() {
  return getRequest(`/topics/all`);
}
export function fetchAllTags() {
  return getRequest(`/tags/all`);
}

export function fetchAllCodingPlatforms() {
  return getRequest(`/coding-platforms`);
}

export function fetchAllCodeForcesTags() {
  return getRequest(`/practice/codeforces-tags/all`);
}
