import React from "react";
import style from "./StudentRegistrationModal.module.scss";

import Modal from "components-shared/Modal";
import StudentRegistration from ".";

export default function StudentRegistrationModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className={style.wrapper} showCloseIcon={!onClose}>
      <StudentRegistration onClose={onClose} />
    </Modal>
  );
}
