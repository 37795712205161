import { useState, useEffect } from "react";
import { stateUpdater } from "utils/helper";
import useQueryParams from "hooks/useQueryParams";

interface modalStates {
  isOpen?: boolean;
  editStudents?: boolean;
  editStudentTime?: boolean;
  confirmModal?: boolean;
  editPubishDesc?: boolean;
  confirmation?: boolean;
  editProblem?: boolean;
  commonDesc?: boolean;
  sets?: boolean;
  createGroup?: boolean;
  editGoal?: boolean;
  createGoal?: boolean;
  closeEditGoal?: boolean;
  isValidate?: boolean;
  semModal?: boolean;
  pwdModal?: boolean;
}
export default function useModalHandler() {
  const [modalStates, setModalStates] = useState<modalStates>({});
  const { updateParams, getParams } = useQueryParams();

  const updateModalState = (modalName, isOpen) => {
    setModalStates((prev) => ({
      ...prev,
      [modalName]: isOpen,
    }));
  };

  const openModal = (modalName, params?: boolean) => {
    updateModalState(modalName, true);
    // params && updateParams({ [modalName]: true });
  };

  const closeModal = (modalName, params: boolean = true) => {
    updateModalState(modalName, false);

    // params && updateParams({ [modalName]: null });
  };

  useEffect(() => {
    const params = getParams();
    setModalStates(params);
  }, []);

  return {
    openModal,
    closeModal,
    modalStates,
  };
}
