import { useState } from "react";
import { postLoginGoogle } from "apis/auth";
import { setAuthToken } from "utils/helper";

import { useAppContext } from "contexts/AppContext";
import { routes } from "index/Routes";

export function useNavbar() {
  const [state] = useState();
  const { toggleProfileUpdateModal } = useAppContext();

  function handleSuccessGoogleLogin(payload) {
    postLoginGoogle({ token: payload.credential })
      .then((resp) => {
        setAuthToken(resp.data.data.token);
        localStorage.setItem("role", "student");

        localStorage.setItem("authToken", resp.data.data.token);
        localStorage.setItem("refreshToken", resp.data.data.refreshToken);
        if (resp.data.isNew) toggleProfileUpdateModal();

        window.location.href = routes.CodingProfileStudent;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return { state, handleSuccessGoogleLogin };
}
